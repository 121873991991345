<template>
  <div>
    <b-container class="white-card">
      <h1>Organisation</h1>
      <b-row class="mb-4 justify-content-around">
        <b-col cols="auto" class="text-center">
          <b-img :src="require('@/assets/Lars Rushton.jpg')" alt="Lars Rushton" fluid class="leader-img rounded-circle"></b-img>
          <p class="leader-name">Lars Rushton</p>
          <p class="leader-role">VD</p>
          <a href="mailto:Lars@telgelack.se">Lars@telgelack.se</a>
          <br/>
          <a href="tel:0706309500">070-630-9500</a>
        </b-col>
        <b-col
         cols="auto" class="text-center">
          <b-img :src="require('@/assets/Joonas Ruotsaari.jpg')" alt="Jonas Ruotsaari" fluid class="leader-img rounded-circle"></b-img>
          <p class="leader-name">Joonas Ruotsaari</p>
          <p class="leader-role">Projektledare</p>
          <a href="mailto:Joonas@telgelack.se">Joonas@telgelack.se</a>
          <br/>
          <a href="tel:0725007100">072-500-7100</a>
        </b-col>
      </b-row>

      <h2 class="worker-class">Transport</h2>
      <b-row>
        <b-col cols="3" class="text-center" v-for="worker in chafWorkers" :key="worker.name">
          <b-img :src="require(`@/assets/${worker.image}`)" :alt="worker.name" fluid></b-img>
          <p class="worker-name">{{ worker.name }}</p>
          <p class="worker-role">{{ worker.role }}</p>
        </b-col>
      </b-row>

      <h2 class="worker-class">Lackerare</h2>
      <b-row>
        <b-col cols="3" class="text-center" v-for="worker in lackWorkers" :key="worker.name">
          <b-img :src="require(`@/assets/${worker.image}`)" :alt="worker.name" fluid></b-img>
          <p class="worker-name">{{ worker.name }}</p>
          <p class="worker-role">{{ worker.role }}</p>
        </b-col>
      </b-row>

      <h2 class="worker-class">Produktion/Lackbiträde</h2>
      <b-row>
        <b-col cols="3" class="text-center" v-for="worker in lbitWorkers" :key="worker.name">
          <b-img :src="require(`@/assets/${worker.image}`)" :alt="worker.name" fluid></b-img>
          <p class="worker-name">{{ worker.name }}</p>
          <p class="worker-role">{{ worker.role }}</p>
        </b-col>
      </b-row>

      <h2 class="worker-class">Ekonomi</h2>
      <b-row>
        <b-col cols="3" class="text-center" v-for="worker in EkonWorkers" :key="worker.name">
          <b-img :src="require(`@/assets/${worker.image}`)" :alt="worker.name" fluid></b-img>
          <p class="worker-name">{{ worker.name }}</p>
          <p class="worker-role">{{ worker.role }}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
export default {
  name: 'OrganizationView',
  components: {
    BImg
  },
  data() {
    return {
      chafWorkers: [
        { name: 'Dariusz', image: 'Dariusz.jpg' },
        { name: 'Anton', image: 'Anton.jpg' },
        { name: 'Steven', image: 'Steven.jpg' },
        { name: 'George', image: 'George.jpg' }
      ],
      lackWorkers: [
        { name: 'Ralf', image: 'Tom.jpg', role: 'Miljö & skyddsansvarig' },
        { name: 'Rezo', image: 'Rezo.jpg' },
        { name: 'Toko', image: 'Toko.jpg' },
        { name: 'Mariusz', image: 'Tom.jpg' }
      ],
      lbitWorkers: [
        { name: 'Andres', image: 'Andres.jpg', role: 'Kvalitet och fordonsansvarig' },
        { name: 'Bashar', image: 'Bashar.jpg' },
        { name: 'Fares', image: 'Fares.jpg' },
        { name: 'Goga', image: 'Goga.jpg' },
        { name: 'Håkan', image: 'Håkan.jpg' },
        { name: 'Tito', image: 'Tito.jpg' }
      ],
      EkonWorkers: [
        { name: 'Jonathan', image: 'Tom.jpg'},
    ]
    }
  }
}
</script>

<style scoped>
.white-card {
  padding: 20px;
  background: rgb(242,232,223);
  background: linear-gradient(180deg, rgba(242,232,223,1) 0%, rgba(217,186,158,1) 100%);
}
.text-center {
  text-align: center;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.leader-img {
  width: 250px;
  margin: 10px;
}
.worker-name,
.leader-name,
.leader-role {
  margin: 2px;
  padding: 0;
  font-weight: bold;
}
.worker-role {
  font-style: italic;
  font-size: 0.9rem;
  margin-bottom: 25px;
}
.worker-class {
  margin-top: 50px;
  font-weight: bold;
}
</style>